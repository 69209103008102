import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t, { useIntl } from '@jetshop/intl';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { useLocation } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import LoadingPage from '../../LoadingPage';
import { smallCaps } from '../../ui/Headings';
import Address from '../Signup/Address';
import LoginFields from '../Signup/LoginFields';
import { theme } from '../../Theme';
import { VoyadoDynamicHeaderRenderer } from './VoyadoLookupField';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useQuery } from '@apollo/react-hooks';
import ConsentQuery from './ConsentQuery.gql';
const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;
  p {
    line-height: 1.5;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  button {
    width: 100%;
    max-width: 100%;
  }
  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }
`;
const Section = styled.section`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 55rem;
  margin-bottom: 3rem;
  > header {
    width: 100%;
    flex-grow: 1;
  }
  > div {
    width: 48%;
  }
  ${theme.below.lg} {
    display: block;
    width: 80%;
    > div {
      width: 100%;
    }
  }
  ${theme.below.sm} {
    width: 100%;
  }
`;

function HeaderWithStatus({ status }) {
  const translate = useIntl();
  const translatedEmail = translate('email');

  if (!status) {
    return (
      <>
        <p>
          {t(
            'Welcome to our online store! We need to create a profile before you continue.'
          )}
        </p>
        <p>
          {t(
            'Please fill in your contact details for shipping and communication.'
          )}
        </p>
      </>
    );
  }

  if (status.isAdditionalDataRequired) {
    return (
      <>
        <p>{t("We're missing some key information.")}</p>
        <p>{t('Please fill in required fields to continue.')}</p>
      </>
    );
  }

  if (status.isNonExistingCustomer) {
    return (
      <>
        <p>
          {t('No customer found with entered { lookupKey }.', {
            lookupKey: translatedEmail
          })}
        </p>
        <p>
          {t(
            'Please fill in your contact details for shipping and communication.'
          )}
        </p>
      </>
    );
  }
  return null;
}

function formatSignupInput(values) {
  const externalAttributes = Object.entries(values?.externalAttributes).map(
    ([name, value]) => {
      if (name === 'gender' || name === 'birthDay') {
        return {
          name,
          value
        };
      } else {
        return {
          name,
          value: JSON.stringify(value)
        };
      }
    }
  );
  console.log('HEJHEJ', Object.entries(values?.consents));
  const consents = Object.entries(values?.consents)?.map(([key, value]) => {
    console.log(key, value);
    return {
      id: key,
      value: value
    };
  });

  console.log('NY DATA', {
    ...values,
    externalAttributes,
    consents
  });
  return {
    ...values,
    externalAttributes,
    consents
  };
}

const checkInitialData = data => {
  if (data?.emailAddress?.encrypted) {
    const phoneNumber = data.emailAddress.encrypted;
    const isValidPhoneNumber =
      /^\+47\d+$/.test(phoneNumber) || /^\d+$/.test(phoneNumber);
    if (isValidPhoneNumber) {
      return {
        mobilePhone: { masked: phoneNumber, encrypted: phoneNumber },
        externalId: data.externalId
      };
    }
  }
  return { mobilePhone: '+47', ...data };
};

function VoyadoSignup() {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const { state } = useLocation();
  const { selectedChannel } = useContext(ChannelContext);
  const { data, loading } = useQuery(ConsentQuery, {
    variables: { channelId: selectedChannel?.id?.toString() }
  });

  if (loading) return null;

  const consents = data?.channel?.settings?.countrySettings?.find(
    c => c.countryCode === selectedChannel.country.code
  )?.privateCustomerConsents;
  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  return (
    <>
      <VoyadoDynamicHeaderRenderer categoryId={340} />
      <SignupFormProvider
        additionalFormatter={formatSignupInput}
        LoadingComponent={<LoadingPage />}
        lookupData={checkInitialData(state?.customer)}
        additionalInitialValues={{
          mobilePhone: '+47',
          emailAddress: '',
          consents: {
            acceptsDigitalMedia: false,
            acceptsTerms: false
          },
          preferences: {
            type: {
              acceptsEmail: false
            }
          },
          externalAttributes: {
            gender: '',
            interest: []
          }
        }}
      >
        <MaxWidth style={{ padding: '2rem' }}>
          <Section>
            <Intl>{t => <Head data={{ title: t('Signup') }} />}</Intl>
            <header>
              <h1
                className={cx('heading', smallCaps)}
                data-testid="signup-page-header"
              >
                {t('Sign up')}
              </h1>
              <HeaderWithStatus status={state?.status} />
            </header>
            <div>
              <Address />
            </div>
            <div style={{ marginTop: '2rem' }}>
              <LoginFields consents={consents} />
            </div>
          </Section>
        </MaxWidth>
      </SignupFormProvider>
    </>
  );
}
export default VoyadoSignup;
