import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import { useIntl } from '@jetshop/intl';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import { css, cx } from 'linaria';
import React from 'react';
import Input, { Label } from '../../Forms/Input';
import { activeSegment } from '../UI/Form';
import { CustomerType } from './CustomerType';
// import { PID } from './PID';
import { styled } from 'linaria/react';
import { Field } from 'formik'; // Add Formik import

const sectionStyle = css`
  margin-top: 2em;

  select:focus {
    outline: none;
  }
`;

const InputWrapper = styled('div')`
  p {
    margin-top: -12px;
    font-style: italic;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #a50000;
  }
`;

const BirthDayField = props => {
  const t = useIntl();
  return (
    <Input
      {...props}
      type="date"
      placeholder="YYYY-MM-DD"
      min="1900-01-01"
      max="2030-12-31"
      label={t('Birthday')}
    />
  );
};

const GenderSignupWrapper = styled.div`
  h4 {
    word-break: break-word;
    font-family: brandon-grotesque, sans-serif;
    line-height: 1.6;
    font-weight: 400;
    visibility: visible;
    font-size: 12px;
    box-sizing: inherit;
  }
  div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
  label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 12px;
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  label::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  label:has(input:checked)::before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNiAxMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KPHRpdGxlPlNoYXBlPC90aXRsZT4KPGRlc2M+Q3JlYXRlZCB1c2luZyBGaWdtYTwvZGVzYz4KPGcgaWQ9IkNoZWNrQ2FudmFzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNDg2OCAtMTU4NTkpIj4KPGcgaWQ9IkNoZWNrU2hhcGUiPgo8dXNlIHhsaW5rOmhyZWY9IiNjaGVja19wYXRoMF9maWxsIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjQ4NjcuNCAxNTg2MCkiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgo8L2c+CjwvZz4KPGRlZnM+CjxwYXRoIGlkPSJjaGVja19wYXRoMF9maWxsIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0gNC43ODE3OCA5LjA1MTIyTCAxLjE5NTQ0IDUuNDY0ODlMIDAgNi42NjAzNEwgNC43ODE3OCAxMS40NDIxTCAxNS4wMjg0IDEuMTk1NDRMIDEzLjgzMyAwTCA0Ljc4MTc4IDkuMDUxMjJaIi8+CjwvZGVmcz4KPC9zdmc+Cg==');
  }
`;

const GenderSignupField = props => {
  const t = useIntl();
  const genderList = ['Female', 'Male', 'Other'];
  return (
    <GenderSignupWrapper role="group" aria-labelledby="gender-selection">
      <h4>{t('Gender')}</h4>
      <div>
        {genderList.map(gender => (
          <label>
            <Field type="radio" {...props} value={gender} />
            {t(gender)}
          </label>
        ))}
      </div>
    </GenderSignupWrapper>
  );
};

export default function Address(props) {
  const { fields, setCountryByCode, countries } = useAddressFields();

  function focusSection() {
    // Used to add a highlight to this section when it is active
    // if (!props.isActive) props.setActiveSection('address');
  }

  const translate = useIntl();
  // const translatedContactDetails = translate('Contact Details');
  // const translatedPleaseFillIn = translate(
  //   'Please fill in your contact details for shipping and communication.'
  // );
  const translatedCountry = translate('Country');

  const renderField = field => {
    if (field.id === '1-NO-0-co') return null;
    if (field.id === '1-NO-0-mobilePhone') {
      return (
        <InputWrapper>
          <Input onFocus={focusSection} {...field.inputProps} />
          <p>Må starte med +47</p>
        </InputWrapper>
      );
    }
    if (field.id === '1-NO-0-lastName') {
      return (
        <>
          <Input onFocus={focusSection} {...field.inputProps} />
          <Field name={`externalAttributes.birthDay`} as={BirthDayField} />
          <Field name={`externalAttributes.gender`} as={GenderSignupField} />
        </>
      );
    }
    return <Input onFocus={focusSection} {...field.inputProps} />;
  };

  return (
    <section
      className={cx(
        'section',
        props.isActive ? 'active' : null,
        sectionStyle,
        activeSegment
      )}
    >
      {/* Field needed for submitting form */}
      <CustomerType />

      <div style={{ marginBottom: '2em', marginTop: '2em' }}>
        <Label htmlFor="country">{translatedCountry}</Label>
        <CountriesInput
          onFocus={focusSection}
          name="country"
          onChange={e => setCountryByCode(e.currentTarget.value)}
          countries={countries}
        />
      </div>

      {/* <PID /> */}

      {fields.map(field => renderField(field))}
    </section>
  );
}
