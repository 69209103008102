import { Link } from 'react-router-dom';
import { Field, useField, useFormikContext } from 'formik';
import { styled } from 'linaria/react';
import { useState } from 'react';
import React from 'react';
import t from '@jetshop/intl';

const ConsentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  input + label {
    &:before {
      display: inline-flex;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      line-height: 1rem;
      border: 1px solid black;
      content: '';
    }
  }
  label {
    position: relative;
    span.linkText {
      cursor: pointer;
      text-decoration: underline;
    }
    span.req {
      font-size: 12px;
      color: #707070;
      margin-left: 0.5em;
    }
  }
  .consent-box {
    padding: 1rem 0;
    a {
      color: #b99864;
    }
  }
  input:checked + label {
    &:before {
      content: '✓';
    }
  }

  .interest-check {
    margin-bottom: 1rem;
    div {
      display: flex;
      label {
        margin-right: 1rem;
      }
    }
  }
  #acceptsTerms {
    order: 9;
  }
  p {
    order: 99;
  }
  label[for='Dame'] {
    order: -1;
  }
`;

const RegistrationgenderInterestsFields = ({ ...props }) => {
  const [field, helpers] = useField(props);
  const genderList = ['Herre', 'Dame', 'Begge deler'];

  const checkFix = e => {
    console.log(typeof field.value, field.value, 'FIELD');
    const val = e.target.value;
    let newVal =
      typeof field.value === 'string'
        ? JSON.parse(field.value || '[]')
        : field.value || [];
    console.log(newVal, 'INITIAL');
    if (newVal?.indexOf(val) > -1) {
      let index = newVal.indexOf(val);
      newVal.splice(index, 1);
    } else {
      newVal.push(val);
    }

    console.log(val, newVal, 'NEWVAL');
    helpers.setValue(newVal);
  };

  return (
    <div>
      {genderList.map((gender, i) => {
        const fieldValue = (i + 1).toString();
        return (
          <>
            <input
              type="checkbox"
              id={gender}
              onChange={checkFix}
              name={gender}
              value={fieldValue}
            />
            <label htmlFor={gender}>{gender}</label>
          </>
        );
      })}
    </div>
  );
};

export const Consents = ({ consents = null }) => {
  const [visible, setVisible] = useState(null);
  const { values } = useFormikContext();
  if (!consents) return null;
  const preferences = {
    acceptsEmail: true,
    acceptsSms: false
  };

  const handleToggle = (e, id) => {
    e.preventDefault();
    if (visible === id) {
      setVisible(null);
    } else {
      setVisible(id);
    }
  };
  const acceptsEmail = values?.preferences?.type?.acceptsEmail;
  console.log(values.consents);
  return (
    <ConsentWrapper>
      {Object.entries(preferences).map(([key, value]) => (
        <div key={key} id={key}>
          <Field
            id={`preferences.type.${key}`}
            name={`preferences.type.${key}`}
            render={({ field, form: { touched, errors } }) => (
              <>
                <input
                  type="checkbox"
                  name={`preferences.type.${key}`}
                  id={`preferences.type.${key}`}
                  {...field}
                  checked={field.value}
                  required={value}
                />
                <label htmlFor={`preferences.type.${key}`}>
                  {t(key)}
                  {value && <span className="req"> (Påkrevet)</span>}
                </label>
              </>
            )}
          />
          {key === 'acceptsEmail' && acceptsEmail && (
            <div className="interest-check">
              <span>Hva er du interessert i å motta informasjon om?</span>
              <div>
                <Field
                  name="externalAttributes.genderInterests"
                  id="externalAttributes.genderInterests"
                  as={RegistrationgenderInterestsFields}
                />
              </div>
            </div>
          )}
        </div>
      ))}
      {consents.map(consent => {
        return (
          <div key={consent.id} id={consent.id}>
            <Field
              id={`consents.${consent.id}`}
              name={`consents.${consent.id}`}
              render={({ field, form: { touched, errors } }) => (
                <>
                  <input
                    type="checkbox"
                    name={`consents.${consent.id}`}
                    id={`consents.${consent.id}`}
                    required={consent.id === 'acceptsTerms'}
                    {...field}
                    checked={field.value}
                  />
                  <label htmlFor={`consents.${consent.id}`}>
                    {consent.id === 'acceptsDigitalMedia' ? (
                      <>
                        <span>
                          Jeg ønsker også digitale annonser basert på min profil
                        </span>
                      </>
                    ) : (
                      <>
                        {consent.title}
                        {consent.linkText && (
                          <>
                            {' '}
                            <span
                              className="linkText"
                              onClick={e => handleToggle(e, consent.id)}
                            >
                              {consent.linkText}
                            </span>
                          </>
                        )}
                        {consent.id === 'acceptsTerms' && (
                          <span className="req"> (Påkrevet)</span>
                        )}
                      </>
                    )}
                  </label>
                </>
              )}
            />
            {visible === consent.id && (
              <div
                className="consent-box"
                dangerouslySetInnerHTML={{ __html: consent.text }}
              />
            )}
          </div>
        );
      })}
      <p style={{ marginTop: '10px' }}>
        Ved å melde deg inn i kundeklubben til Ferner Jacobsen samtykker du til
        at Ferner Jacobsen AS sender eksklusiv informasjon om nyheter,
        kampanjer, lanseringer og invitasjoner til kundekvelder via SMS og
        e-post. Du samtykker også til at Ferner Jacobsen AS samler inn og
        behandler ditt navn, etternavn, kjønn, adresse, telefonnummer,
        fødselsdato og e-postadresse for markedsføringsformål, det vil si at du
        vil motta relevant og tilpasset informasjon. Ferner Jacobsen AS
        behandler dine personopplysninger i samsvar med vår{' '}
        <Link to="/personvernserklaring" style={{ color: 'black' }}>
          personvernerklæring.
        </Link>
      </p>
    </ConsentWrapper>
  );
};
