import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import { StyledTrendButton } from '../Voyado/VoyadoLookup';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';
import { SmallCaps } from '../../ui/Headings';
import { Consents } from '../Voyado/VoyadoConsents';

export default function LoginFields(props) {
  const { fields } = useLoginFields();
  const { globalError, isSubmitting } = useSignupForm();

  return (
    <>
      <SmallCaps className="heading">{t('Email & Password')}</SmallCaps>

      {globalError && (
        <GlobalError style={{ marginBottom: '2em' }}>{globalError}</GlobalError>
      )}

      {fields.map(({ inputProps }) => {
        return (
          <Input
            {...inputProps}
            inputMode={inputProps.type === 'email' ? 'email' : 'password'}
            type={inputProps.type === 'email' ? 'text' : 'password'}
          />
        );
      })}

      <Consents consents={props.consents} />
      <StyledTrendButton
        data-testid="sign-up-button"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
      </StyledTrendButton>
    </>
  );
}
