import { SignupContext } from '@jetshop/core/components/Auth/signup-context';
import t from '@jetshop/intl';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';
import React, { useContext } from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';

const checkboxWrapStyle = css`
  //Field needed for submitting form
  display: none !important;

  margin-bottom: 1em;
  display: flex;
  label {
    justify-content: flex-start;
    align-items: center;

    span {
      padding: 2px;
    }
    + label {
      margin-left: 1.5em;
    }
  }

  svg {
    path {
      fill: #fff;
    }
  }

  &.first-active {
    label:first-of-type {
      span {
        border-color: ${theme.colors.gold};
        background: ${theme.colors.gold};
      }
    }
  }
  &.second-active {
    label:nth-of-type(2) {
      span {
        border-color: ${theme.colors.gold};
        background: ${theme.colors.gold};
      }
    }
  }
`;

// Checkbox group variables
const items = [
  { label: t('Private'), val: 'private' },
  { label: t('Company'), val: 'company' }
];

export function CustomerType() {
  const { isUserTypeBusiness, setIsUserTypeBusiness } = useContext(
    SignupContext
  );

  function handleChange(type) {
    setIsUserTypeBusiness(type === 'company');
  }

  const selectedItem = !isUserTypeBusiness ? items[0].val : items[1].val;

  return (
    <CheckboxGroup
      items={items}
      selectedItem={selectedItem}
      handleChange={handleChange}
      className={cx(
        checkboxWrapStyle,
        !isUserTypeBusiness ? 'first-active' : 'second-active'
      )}
    />
  );
}
